import useRole from "./useRole";
import peakaLogo from "../../app/assets/images/peaka-logo-light.svg";
import { useGetEmbeddedCompanyLogoQuery } from "../../app/services/apiKey";

const useCustomLogo = (type: string) => {
  const role = useRole();
  const { data } = useGetEmbeddedCompanyLogoQuery(null, {
    skip: role !== "PARTNER_GUEST"
  });

  if (role === "PARTNER_GUEST" && data) {
    switch (type) {
      case "icon":
      case "text-icon-horizontal":
        return (
          <img src={data} style={{ width: "inherit", height: "inherit" }} />
        );
      case "text-icon-vertical":
        return <img src={data} style={{ width: "32px", height: "32px" }} />;
      case "peaka-logo-svg":
        return <img src={data} width={24} height={24} />;
      default:
        return <i className="fa-kit fa-peaka-icon" />;
    }
  } else {
    switch (type) {
      case "icon":
        return <i className="fa-kit fa-peaka-icon" />;
      case "text-icon-vertical":
        return <i className="fa-kit fa-peaka-vertical " />;
      case "text-icon-horizontal":
        return <i className="fa-kit fa-peaka-horizontal" />;
      case "peaka-logo-svg":
        return <img width={24} height={24} src={peakaLogo} alt="peaka-logo" />;
      default:
        return <i className="fa-kit fa-peaka-icon" />;
    }
  }
};

export default useCustomLogo;
