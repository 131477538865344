import { api } from "./api";
import type {
  ICreateProjectArgs,
  IGetProjectArgs,
  IGrantRoleResponse,
  IListProjectsArgs,
  IListProjectsResponse,
  IProject,
  IRevokeRoleArgs,
  IUpdateProjectArgs
} from "./types/projectTypings";
import type {
  IGetAllGrantsResponseForGroup,
  IGetAllGrantsResponseForUser
} from "./types/workspaceTypes";

export const projectApi = api.injectEndpoints({
  endpoints: (builder) => ({
    listProjects: builder.query<IListProjectsResponse, IListProjectsArgs>({
      query: ({
        organizationId,
        workspaceId,
        pageSize,
        pageNumber,
        searchQuery = ""
      }) => {
        let queryArgs = "";
        if (
          typeof pageSize !== "undefined" &&
          typeof pageNumber !== "undefined"
        ) {
          queryArgs = `pageSize=${pageSize}&pageNumber=${pageNumber}&name=${searchQuery}`;
        } else {
          queryArgs = `pageSize=100000&pageNumber=1&name=${searchQuery}`;
        }
        return {
          url: `/management/organizations/${organizationId}/workspaces/${workspaceId}/projects?${queryArgs}`
        };
      },
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        return `${endpointName}-${queryArgs.workspaceId}`;
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      providesTags: ["Projects"]
    }),
    createProject: builder.mutation<IProject, ICreateProjectArgs>({
      query: ({ name, description, organizationId, workspaceId }) => ({
        url: `/management/organizations/${organizationId}/workspaces/${workspaceId}/projects`,
        method: "POST",
        body: {
          name,
          description
        }
      }),
      invalidatesTags: ["Projects"]
    }),
    getProject: builder.query<IProject, IGetProjectArgs>({
      query: ({ organizationId, workspaceId, projectId }) => ({
        url: `/management/organizations/${organizationId}/workspaces/${workspaceId}/projects/${projectId}`
      })
    }),
    updateProject: builder.mutation<IProject, IUpdateProjectArgs>({
      query: ({
        organizationId,
        workspaceId,
        projectId,
        name,
        description
      }) => ({
        url: `/management/organizations/${organizationId}/workspaces/${workspaceId}/projects/${projectId}`,
        method: "PUT",
        body: {
          name,
          description
        }
      }),
      invalidatesTags: ["Projects"]
    }),
    deleteProject: builder.mutation<void, IGetProjectArgs>({
      query: ({ organizationId, workspaceId, projectId }) => ({
        url: `/management/organizations/${organizationId}/workspaces/${workspaceId}/projects/${projectId}`,
        method: "DELETE"
      }),
      invalidatesTags: ["Projects"]
    }),
    revokeRole: builder.mutation<void, IRevokeRoleArgs>({
      query: ({
        organizationId,
        workspaceId,
        projectId,
        entityId,
        entityType,
        roleId
      }) => ({
        url: `/management/organizations/${organizationId}/workspaces/${workspaceId}/projects/${projectId}/revoke-role`,
        method: "POST",
        body: {
          entityType,
          entityId,
          roleId
        }
      })
    }),
    grantRole: builder.mutation<IGrantRoleResponse, IRevokeRoleArgs>({
      query: ({
        organizationId,
        workspaceId,
        projectId,
        entityId,
        entityType,
        roleId
      }) => ({
        url: `/management/organizations/${organizationId}/workspaces/${workspaceId}/projects/${projectId}/grant-role`,
        method: "POST",
        body: {
          entityType,
          entityId,
          roleId
        }
      })
    }),
    copyProject: builder.mutation<IProject, IUpdateProjectArgs>({
      query: ({ organizationId, workspaceId, projectId, name }) => ({
        url: `/management/organizations/${organizationId}/workspaces/${workspaceId}/projects/${projectId}/copy`,
        method: "POST",
        body: { name }
      }),
      invalidatesTags: ["Projects"]
    }),
    getGrantedRoles: builder.query<
      (IGetAllGrantsResponseForUser | IGetAllGrantsResponseForGroup)[],
      IGetProjectArgs
    >({
      query: ({ organizationId, workspaceId, projectId }) => ({
        url: `/management/organizations/${organizationId}/workspaces/${workspaceId}/projects/${projectId}/grants`
      })
    })
  })
});

export const {
  useCopyProjectMutation,
  useCreateProjectMutation,
  useDeleteProjectMutation,
  useGetGrantedRolesQuery,
  useGetProjectQuery,
  useGrantRoleMutation,
  useListProjectsQuery,
  useRevokeRoleMutation,
  useUpdateProjectMutation
} = projectApi;
