import { api } from "./api";
import type { IRoleDefinition } from "./types/roleTypes";

const rolesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    listRoles: builder.query<IRoleDefinition[], { organizationId: string }>({
      query: ({ organizationId }) => ({
        url: `/management/organizations/${organizationId}/config/roles`,
        method: "GET"
      })
    }),
    listGrantablePermissions: builder.query<string[], void>({
      query: () => ({
        url: `/management/config/grantable-permissions`
      })
    })
  })
});

export const { useListRolesQuery, useListGrantablePermissionsQuery } = rolesApi;
