import {
  PeakaButton,
  PeakaChipInput,
  PeakaTextArea
} from "@code2io/fe-studio-component-library";
import PeakaDialog from "../../app/utils/peakaDialog";
import { validateTextAsEmail } from "../../app/utils/validateTextAsEmail";
import { useState } from "react";
import RoleSelector from "../createOrganization/roleSelector";
import type { IRole } from "../../app/services/types/organizationTypes";
import { useInviteUserToOrganizationMutation } from "../../app/services/organization";
import { useAppSelector } from "../../app/hooks";
import { selectSelectedOrganization } from "../createOrganization/organizationSlice";
import toast from "../../app/utils/toast/toast";

export interface IInviteMemberModalProps {
  visibility: boolean;
  setVisibility: (data: boolean) => void;
}

const InviteMemberModal = ({
  visibility,
  setVisibility
}: IInviteMemberModalProps) => {
  const organizationId = useAppSelector(
    selectSelectedOrganization
  ).organizationId;
  const [isChipInputInvalid, setIsChipInputInvalid] = useState<boolean>(false);
  const [emailList, setEmailList] = useState<string[]>([]);
  const [selectedRole, setSelectedRole] = useState<IRole>("member");
  const [note, setNote] = useState<string>();
  const [inviteUserToOrganization, { isLoading: invitationLoading }] =
    useInviteUserToOrganizationMutation();
  return (
    <PeakaDialog
      visible={visibility}
      onHide={() => setVisibility(false)}
      header="Invite New Member"
      content={
        <div>
          <PeakaChipInput
            helpText={
              isChipInputInvalid
                ? "Please check the email that you entered"
                : "You can seperate emails with comma (,)"
            }
            separator=","
            onBlur={(e) => {
              if (e.target.value && validateTextAsEmail(e.target.value)) {
                setIsChipInputInvalid(false);
                setEmailList([...emailList, e.target.value]);
                e.target.value = "";
              } else if (e.target.value) {
                setIsChipInputInvalid(true);
                return false;
              } else {
                setIsChipInputInvalid(false);
              }
            }}
            onAdd={(e) => {
              if (validateTextAsEmail(e.value)) {
                setIsChipInputInvalid(false);
              } else {
                setIsChipInputInvalid(true);
                return false;
              }
            }}
            invalid={isChipInputInvalid}
            value={emailList}
            onChange={(e) => {
              if (e.value) {
                setEmailList(e.value);
              }
            }}
            id="sendAppEmail"
            max={10}
            withDropdown={
              <RoleSelector
                className="absolute"
                style={{
                  right: ".5rem",
                  alignSelf: "anchor-center"
                }}
                value={selectedRole}
                onRoleChanged={(e) => {
                  setSelectedRole(e);
                }}
              />
            }
            placeholder="Email Address"
            size="lg"
            wrapperClassName="w-full mb-3"
          />
          <PeakaTextArea
            style={{ resize: "none" }}
            wrapperClassName="w-full"
            label="Invitation message"
            autoComplete="off"
            rows={2}
            id="sendAppNote"
            value={note}
            onChange={(e) => setNote(e.target.value)}
            autoResize
          />
        </div>
      }
      footer={
        <div className="flex justify-content-end gap-2">
          <PeakaButton
            label="Cancel"
            severity="secondary"
            bordered
            onClick={() => setVisibility(false)}
          />
          <PeakaButton
            label="Invite"
            disabled={emailList.length === 0 || invitationLoading}
            loading={invitationLoading}
            onClick={async () => {
              const responses = await Promise.all(
                emailList.map((invitedUserEmail) => {
                  return inviteUserToOrganization({
                    organizationId,
                    invitedUserEmail,
                    note,
                    role: selectedRole,
                    addedGroupId: null
                  });
                })
              );
              if (responses.length === emailList.length) {
                toast.current?.show({
                  severity: "success",
                  summary: "Invitation Sent",
                  detail: "Your invitation requests are sent successfully."
                });
                setVisibility(false);
              }
            }}
          />
        </div>
      }
    />
  );
};

export default InviteMemberModal;
