import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useAppSelector } from "../../../app/hooks";
import { selectSelectedOrganization } from "../../createOrganization/organizationSlice";
import {
  useDeleteMemberFromOrganizationMutation,
  useGetOrganizationMemberQuery
} from "../../../app/services/organization";
import type {
  IMemberGroup,
  IOrganizationMember
} from "../../../app/services/types/organizationTypes";
import { getUserLogo } from "../helpers";
import {
  PeakaAvatar,
  PeakaButton,
  PeakaIcon,
  PeakaInputText,
  PeakaTag
} from "@code2io/fe-studio-component-library";
import { Menu } from "primereact/menu";
import { useMemo, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faEllipsis,
  faSearch,
  faUsers
} from "@fortawesome/pro-regular-svg-icons";
import { useListGroupsQuery } from "../../../app/services/group";
import PeakaDialog from "../../../app/utils/peakaDialog";
import toast from "../../../app/utils/toast/toast";
import usePermission from "../../auth/usePermission";
import { selectUser } from "../../auth/authSlice";
import { lowerCase, startCase } from "lodash";
import RoleSelector from "./RoleSelector";
import { useDebounce } from "primereact/hooks";
import empty from "../../../app/assets/svg/empty-workspace.svg";

const OrganizationMembers = () => {
  const organizationId = useAppSelector(
    selectSelectedOrganization
  ).organizationId;
  const [pageNumber, setPageNumber] = useState<number>(1);
  const currentUser = useAppSelector(selectUser);
  const rowHeight = 43; // in pixels
  const pageSize = 40;
  const currentPageRef = useRef(0);
  const lastScrollTop = useRef(0);
  const loadMoreTimeoutId = useRef(0);
  const windowSize = Math.round(
    ((window.innerHeight / rowHeight) * 3) / pageSize
  );
  const [searchQuery, debouncedSearchQuery, setSearchQuery] = useDebounce(
    "",
    300
  );
  const {
    data: organizationMembers,
    isLoading: membersLoading,
    isFetching,
    refetch: refetchMembers
  } = useGetOrganizationMemberQuery(
    { organizationId, pageNumber, pageSize, searchQuery: debouncedSearchQuery },
    { skip: !organizationId }
  );

  const { data: listGroups } = useListGroupsQuery(
    { organizationId },
    { skip: !organizationId }
  );
  const menu = useRef<Menu | null>(null);
  const [deleteMemberFromOrganization, { isLoading: isDeleting }] =
    useDeleteMemberFromOrganizationMutation();
  const [deleteConfirmVisible, setDeleteConfirmVisible] =
    useState<boolean>(false);
  const [deleteMemberId, setDeleteMemberId] = useState<string>();
  const { organizationPermissions } = usePermission({});

  return (
    listGroups &&
    organizationMembers && (
      <div>
        <PeakaInputText
          leftIcon={<FontAwesomeIcon icon={faSearch} />}
          value={searchQuery}
          wrapperClassName="pk-padding-bottom-lg"
          placeholder="Search within the workspace"
          onChange={(e) => setSearchQuery(e.target.value)}
          style={{ width: "13.75rem" }}
        />
        <DataTable
          value={organizationMembers}
          size="small"
          emptyMessage={
            <div className="workspace-page">
              <div className="empty-workspace-container">
                <img src={empty} />
                <div className="empty-workspace-header flex flex-column">
                  <div className="empty-workspace-description">
                    The member you are looking for could not be found.
                  </div>
                </div>
              </div>
            </div>
          }
          scrollable
          scrollHeight="calc(100vh - 2.25rem - 128px)"
          pt={{
            wrapper: {
              onScrollCapture: (e) => {
                const { scrollHeight, scrollTop, clientHeight } =
                  e.currentTarget;
                if (
                  lastScrollTop.current !== scrollTop &&
                  Math.abs(scrollHeight - scrollTop - clientHeight) <= 8
                ) {
                  clearTimeout(loadMoreTimeoutId.current);
                  loadMoreTimeoutId.current = window.setTimeout(() => {
                    setPageNumber(pageNumber + 1);
                    clearTimeout(loadMoreTimeoutId.current);
                  }, 10);
                }
              }
            }
          }}
          loading={membersLoading || isFetching}
          className="group-organization-members"
        >
          <Column field="name" header="User" body={renderUser} />
          <Column
            field="groups"
            header="Groups"
            body={(data: IOrganizationMember) => (
              <GroupsBody groups={data.groups} />
            )}
          />
          <Column
            field="role"
            header="Role"
            body={(rowData: IOrganizationMember) => {
              return (
                <div style={{ display: "flex" }}>
                  {startCase(lowerCase(rowData.role))}
                  {rowData.role !== "owner" &&
                    rowData.email !== currentUser?.email &&
                    organizationPermissions?.includes("manage") && (
                      <RoleSelector
                        rowData={rowData}
                        refetchMembers={refetchMembers}
                      />
                    )}
                </div>
              );
            }}
          />
          {organizationPermissions?.includes("remove_member") && (
            <Column
              className="flex justify-content-end"
              pt={{
                bodyCell: {
                  style: {
                    minHeight: "45px"
                  }
                }
              }}
              body={(rowData: IOrganizationMember) =>
                rowData.role !== "owner" &&
                rowData.email !== currentUser?.email ? (
                  <div>
                    <PeakaButton
                      size="sm"
                      ghost={true}
                      severity="secondary"
                      icon={<FontAwesomeIcon icon={faEllipsis} size="xs" />}
                      onClick={(e) => {
                        menu.current?.toggle(e);
                      }}
                    />

                    <Menu
                      appendTo={document.body}
                      className=""
                      model={[
                        {
                          icon: "fa-regular fa-trash",
                          command: () => {
                            setDeleteConfirmVisible(true);
                            setDeleteMemberId(rowData.userId);
                          },
                          label: "Remove Member",
                          className: "delete"
                        }
                      ]}
                      pt={{
                        menuitem: {
                          className: "app-list-dashboard-context-p-menuitem",
                          style: {
                            padding:
                              "var(--padding-lg) var(--padding-xl) !important"
                          }
                        },
                        icon: {
                          className:
                            "pk-body-text-sm app-list-dashboard-context-p-menuitem-icon"
                        },
                        label: {
                          className:
                            "pk-body-text-sm app-list-dashboard-context-p-menuitem-text"
                        }
                      }}
                      popup
                      ref={menu}
                    />
                  </div>
                ) : (
                  <div> &nbsp;</div>
                )
              }
            />
          )}
        </DataTable>
        <PeakaDialog
          visible={deleteConfirmVisible}
          size="sm"
          content={
            <div className="workspace-delete-confirm-modal flex flex-column gap-2">
              <div className="title">
                Are you sure to remove user from the organization
              </div>
              <div className="description">
                This action is irreversible, and all associated data will be
                permanently removed.
              </div>
            </div>
          }
          header="Remove User"
          onHide={() => setDeleteConfirmVisible(false)}
          footer={
            <div className="flex flex-row justify-content-end gap-2">
              <PeakaButton
                label="Cancel"
                onClick={() => setDeleteConfirmVisible(false)}
                bordered
                severity="secondary"
              />
              <PeakaButton
                label="Delete"
                severity="destructive"
                disabled={isDeleting}
                loading={isDeleting}
                onClick={async () => {
                  if (!deleteMemberId) return;
                  const deleted = await deleteMemberFromOrganization({
                    organizationId,
                    memberId: deleteMemberId
                  });

                  if ("data" in deleted) {
                    toast.current?.show({
                      severity: "success",
                      summary: `Success`,
                      detail: "User is removed from organization successfully",
                      life: 5000,
                      closable: true
                    });

                    setDeleteConfirmVisible(false);
                    setDeleteMemberId(undefined);
                  }
                }}
              />
            </div>
          }
        />
      </div>
    )
  );

  function renderUser(data: IOrganizationMember) {
    return (
      <div className="flex align-items-center gap-2">
        <PeakaAvatar
          label={getUserLogo(data.firstName, data.lastName)}
          size="sm"
        />
        <div className="flex flex-column">
          <span className="pk-header-color pk-label-text-sm pk-font-medium">
            {data.firstName} {data.lastName}
          </span>
          <span className="pk-text-primary pk-label-text-sm">{data.email}</span>
        </div>
      </div>
    );
  }
};

const GroupsBody = ({ groups }: { groups: IMemberGroup[] | null }) => {
  const menu = useRef<Menu>(null);

  const menuItems = useMemo(() => {
    return groups?.map((groupItem: IMemberGroup) => {
      const { groupName, memberCount } = groupItem;
      return {
        template: (item: any, options: any) => {
          return (
            <div
              role="button"
              onClick={(e) => options.onClick(e)}
              className="flex align-items-center gap-2"
            >
              <PeakaIcon icon={<FontAwesomeIcon icon={faUsers} />} />
              <div className="flex flex-column">
                <span className="pk-header-color pk-label-text-sm pk-font-medium">
                  {groupName}
                </span>
                <span className="pk-text-primary pk-label-text-sm">
                  {memberCount} members
                </span>
              </div>
            </div>
          );
        }
      };
    });
  }, [groups]);

  return (
    <>
      <PeakaTag
        className="z-10"
        onClick={(event) => {
          if (groups && groups.length > 0) menu.current?.toggle(event);
        }}
        text={
          groups
            ? groups.length === 1
              ? groups[0].groupName
              : groups.length.toString()
            : "0"
        }
        size="sm"
        iconPosition="right"
        icon={
          groups && groups.length > 0 ? (
            <FontAwesomeIcon icon={faChevronDown} />
          ) : (
            ""
          )
        }
      />

      <Menu
        className={"app-list-dashboard-context"}
        model={menuItems}
        popup
        ref={menu}
        pt={{
          menuitem: {
            className: "app-list-dashboard-context-p-menuitem"
          },
          icon: {
            className: "app-list-dashboard-context-p-menuitem-icon"
          },
          label: {
            className: "app-list-dashboard-context-p-menuitem-text"
          }
        }}
      />
    </>
  );
};

export default OrganizationMembers;
