import { useEffect, useState } from "react";
import { PeakaButton } from "@code2io/fe-studio-component-library";
import { faArrowLeft, faPlus } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TabPanel } from "primereact/tabview";
import { Outlet } from "react-router-dom";
import { TabView } from "primereact/tabview";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import CreateGroupOrganizationModal from "./createGroupOrganizationModal";
import usePermission from "../../auth/usePermission";

const GroupOrganization = () => {
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const location = useLocation();
  const [createGroupDialogVisibility, setCreateGroupDialogVisibility] =
    useState<boolean>(false);
  const { organizationPermissions } = usePermission({});

  useEffect(() => {
    const lastPath =
      location.pathname.split("/")[location.pathname.split("/").length - 1];
    if (lastPath === "groups") {
      setActiveIndex(1);
    } else {
      setActiveIndex(0);
    }
  }, [location.pathname]);

  return (
    <div className="workspace-settings">
      <div className="flex align-items-center justify-content-between gap-3">
        <div className="flex align-items-center gap-2 mb-3">
          <PeakaButton
            thin
            size="sm"
            severity="secondary"
            icon={<FontAwesomeIcon icon={faArrowLeft} />}
          />
          <div className="flex flex-column gap-1">
            <h1 className="pk-font-semibold pk-heading-text-md pk-header-color">
              Test Organization
            </h1>
            <h2 className="pk-font-medium pk-heading-text-sm pk-text-label">
              Member Settings
            </h2>
          </div>
        </div>
        {activeIndex === 1 &&
          organizationPermissions?.includes("create_group") && (
            <>
              <PeakaButton
                onClick={() => setCreateGroupDialogVisibility(true)}
                label="Create Group"
                icon={<FontAwesomeIcon icon={faPlus} />}
              />
              <CreateGroupOrganizationModal
                visible={createGroupDialogVisibility}
                onHide={() => setCreateGroupDialogVisibility(false)}
              />
            </>
          )}

        {activeIndex === 0 && (
          <>
            <PeakaButton
              label="Add Member"
              icon={<FontAwesomeIcon icon={faPlus} />}
            />
          </>
        )}
      </div>

      <TabView
        activeIndex={activeIndex}
        className="workspace-settings-tabs"
        onTabChange={(e) => {
          if (e.index === 0) {
            navigate("members");
          } else {
            navigate("groups");
          }
        }}
        pt={{
          nav: {
            className: "gap-3"
          }
        }}
      >
        <TabPanel
          header={
            <div className="flex align-items-center gap-2">
              <span>Members</span>
              <span>(1)</span>
            </div>
          }
        >
          <Outlet />
        </TabPanel>
        <TabPanel
          header={
            <div className="flex align-items-center gap-2">
              <span>Groups</span>
              <span>(0)</span>
            </div>
          }
        >
          <Outlet />
        </TabPanel>
      </TabView>
    </div>
  );
};

export default GroupOrganization;
