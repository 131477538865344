import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import { selectSelectedOrganization } from "../../createOrganization/organizationSlice";
import { PeakaButton } from "@code2io/fe-studio-component-library";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAdd,
  faChevronLeft,
  faPlus
} from "@fortawesome/pro-regular-svg-icons";
import { TabPanel, TabView } from "primereact/tabview";
import { useEffect, useState } from "react";
import InviteMemberModal from "../inviteMemberModal";
import CreateGroupOrganizationModal from "../groupOrganization/createGroupOrganizationModal";
import usePermission from "../../auth/usePermission";

const OrganizationSettingsPage = () => {
  const selectedOrganization = useAppSelector(selectSelectedOrganization);
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const location = useLocation();
  const [createGroupDialogVisibility, setCreateGroupDialogVisibility] =
    useState<boolean>(false);
  const [inviteMemberVisible, setInviteMemberVisible] =
    useState<boolean>(false);
  const { organizationPermissions } = usePermission({});

  useEffect(() => {
    const lastPath =
      location.pathname.split("/")[location.pathname.split("/").length - 1];
    if (lastPath === "members") {
      setActiveIndex(1);
    } else if (lastPath === "settings") {
      setActiveIndex(0);
    } else if (lastPath === "invitations") {
      setActiveIndex(3);
    } else {
      setActiveIndex(2);
    }
  }, [location.pathname]);

  return (
    <div>
      <div className="organization-settings-page">
        <div className="organization-settings-header flex flex-row justify-content-between align-items-center">
          <div className="flex flex-row gap-2 align-items-center">
            <div>
              <PeakaButton
                icon={<FontAwesomeIcon icon={faChevronLeft} />}
                thin
                severity="secondary"
                onClick={() => navigate("/home")}
              />
            </div>
            <div className="page-title flex flex-column gap-1">
              <div className="organization-name">
                {selectedOrganization.organizationName}
              </div>
              <div className="organization-desc">Organization Settings</div>
            </div>
          </div>
          {activeIndex === 1 &&
            organizationPermissions?.includes("add_member") && (
              <div className="flex align-self-end">
                <PeakaButton
                  label="Invite Members"
                  icon={<FontAwesomeIcon icon={faAdd} />}
                  onClick={() => setInviteMemberVisible(true)}
                />
              </div>
            )}
          {activeIndex === 2 &&
            organizationPermissions?.includes("create_group") && (
              <>
                <PeakaButton
                  onClick={() => setCreateGroupDialogVisibility(true)}
                  label="Create Group"
                  icon={<FontAwesomeIcon icon={faPlus} />}
                />
                <CreateGroupOrganizationModal
                  visible={createGroupDialogVisibility}
                  onHide={() => setCreateGroupDialogVisibility(false)}
                />
              </>
            )}
        </div>
        <div className="tab-container">
          <TabView
            activeIndex={activeIndex}
            className="organization-settings-tabs"
            onTabChange={(e) => {
              if (e.index === 0) {
                navigate("settings");
              } else if (e.index === 1) {
                navigate("members");
              } else if (e.index === 3) {
                navigate("invitations");
              } else {
                navigate("groups");
              }
            }}
            pt={{
              nav: {
                className: "gap-3"
              }
            }}
          >
            {organizationPermissions?.includes("manage") && (
              <TabPanel header="Organization Info">
                <Outlet />
              </TabPanel>
            )}
            {organizationPermissions?.includes("view_members") && (
              <TabPanel header="Organization Members">
                <Outlet />
              </TabPanel>
            )}
            {organizationPermissions?.includes("view_groups") && (
              <TabPanel header="Groups">
                <Outlet />
              </TabPanel>
            )}
            {organizationPermissions?.includes("view_members") && (
              <TabPanel header="Pending Invitations">
                <Outlet />
              </TabPanel>
            )}
          </TabView>
        </div>
      </div>
      <InviteMemberModal
        visibility={inviteMemberVisible}
        setVisibility={setInviteMemberVisible}
      />
    </div>
  );
};

export default OrganizationSettingsPage;
