import { api } from "./api";
import type {
  ICreateOrganizationArgs,
  ICreateOrganizationResponse,
  IInviteUserToOrganizationArgs,
  IInviteUserToOrganizationResponse,
  IOrganizationDetail,
  IOrganizationMember,
  IOrganizationMemberRoleUpdateArgs,
  IRenameOrganizationArgs
} from "./types/organizationTypes";

export const organizationApi = api.injectEndpoints({
  endpoints: (builder) => ({
    listOrganizations: builder.query<IOrganizationDetail[], void | null>({
      query: () => ({
        url: `/management/organizations`
      }),
      providesTags: ["Organizations"]
    }),
    getOrganization: builder.query<
      IOrganizationDetail,
      { organizationId: string }
    >({
      query: ({ organizationId }) => ({
        url: `/management/organizations/${organizationId}`
      })
    }),
    createOrganization: builder.mutation<
      ICreateOrganizationResponse,
      ICreateOrganizationArgs
    >({
      query: ({ name, description, iconTempFileId }) => ({
        url: `/management/organizations`,
        method: "POST",
        body: {
          name,
          description,
          iconTempFileId
        }
      }),
      invalidatesTags: ["Organizations"]
    }),
    renameOrganization: builder.mutation<
      ICreateOrganizationResponse,
      IRenameOrganizationArgs
    >({
      query: ({ organizationId, name, description, iconTempFileId }) => ({
        url: `/management/organizations/${organizationId}`,
        method: "PUT",
        body: {
          name,
          description,
          iconTempFileId
        }
      }),
      invalidatesTags: ["Organizations"]
    }),
    deleteOrganization: builder.mutation<void, { organizationId: string }>({
      query: ({ organizationId }) => ({
        url: `/management/organizations/${organizationId}`,
        method: "DELETE"
      }),
      invalidatesTags: ["Organizations"]
    }),
    inviteUserToOrganization: builder.mutation<
      IInviteUserToOrganizationResponse,
      IInviteUserToOrganizationArgs
    >({
      query: ({
        organizationId,
        invitedUserEmail,
        note,
        role,
        addedGroupId
      }) => ({
        url: `/management/organizations/${organizationId}/invitations`,
        method: "POST",
        body: {
          invitedUserEmail,
          note,
          role,
          addedGroupId
        }
      }),
      invalidatesTags: ["Invitations"]
    }),
    listInvitationsToOrganization: builder.query<
      IInviteUserToOrganizationResponse[],
      { organizationId: string }
    >({
      query: ({ organizationId }) => ({
        url: `/management/organizations/${organizationId}/invitations`,
        method: "GET"
      }),
      providesTags: ["Invitations"]
    }),
    getOrganizationMember: builder.query<
      IOrganizationMember[],
      {
        organizationId: string;
        pageSize?: number;
        pageNumber?: number;
        searchQuery?: string;
      }
    >({
      query: ({ organizationId, pageSize, pageNumber, searchQuery = "" }) => {
        let queryParams = `?search=${searchQuery}`;
        if (pageSize) {
          if (queryParams) {
            queryParams += `&pageSize=${pageSize}`;
          } else {
            queryParams = `?pageSize=${pageSize}&search=${searchQuery}`;
          }
          queryParams;
        }
        if (pageNumber) {
          if (queryParams) {
            queryParams += `&pageNumber=${pageNumber}`;
          } else {
            queryParams = `?pageNumber=${pageNumber}&search=${searchQuery}`;
          }
        }
        return {
          url: `/management/organizations/${organizationId}/members${queryParams}`,
          method: "GET"
        };
      },
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        return `${endpointName}-${queryArgs.organizationId}`;
      },
      merge: (currentCache, newItems, { arg }) => {
        if (arg.pageNumber === 1 || !arg.pageNumber) return newItems;
        else {
          return [...currentCache, ...newItems];
        }
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      }
    }),
    deleteInvitation: builder.mutation<
      void,
      { organizationId: string; invitationId: string }
    >({
      query: ({ organizationId, invitationId }) => ({
        url: `/management/organizations/${organizationId}/invitations/${invitationId}`,
        method: "DELETE"
      }),
      invalidatesTags: ["Invitations"]
    }),
    listPersonalInvitations: builder.query<
      IInviteUserToOrganizationResponse[],
      void
    >({
      query: () => ({
        url: `/organization-invitations`,
        method: "GET"
      }),
      providesTags: ["Invitations"]
    }),
    acceptInvitation: builder.mutation<void, { invitationId: string }>({
      query: ({ invitationId }) => ({
        url: `/organization-invitations/${invitationId}/accept`,
        method: "POST"
      }),
      invalidatesTags: ["Invitations", "Organizations"]
    }),
    rejectInvitation: builder.mutation<void, { invitationId: string }>({
      query: ({ invitationId }) => ({
        url: `/organization-invitations/${invitationId}/reject`,
        method: "POST"
      }),
      invalidatesTags: ["Invitations", "Organizations"]
    }),
    updateOrganizationMemberRole: builder.mutation<
      void,
      IOrganizationMemberRoleUpdateArgs
    >({
      query: ({ organizationId, memberId, role }) => ({
        url: `/management/organizations/${organizationId}/members/${memberId}`,
        method: "PUT",
        body: {
          role
        }
      })
    }),
    deleteMemberFromOrganization: builder.mutation<
      void,
      { organizationId: string; memberId: string }
    >({
      query: ({ organizationId, memberId }) => ({
        url: `/management/organizations/${organizationId}/members/${memberId}`,
        method: "DELETE"
      })
    }),
    uploadOrganizationIcon: builder.mutation<
      {
        fileId: string;
      },
      { icon: FormData; organizationId: string }
    >({
      query: ({
        icon,
        organizationId
      }: {
        organizationId: string;
        icon: FormData;
      }) => {
        console.log({ icon });
        return {
          url: `/management/organizations/${organizationId}/uploadIcon`,
          method: "POST",
          body: icon,
          providesTags: ["OrganizationIcon"]
        };
      }
    }),
    getOrganizationIcon: builder.query<string, { organizationId: string }>({
      query: ({ organizationId }) => ({
        method: "GET",
        responseHandler: async (response) =>
          URL.createObjectURL(await response.blob()),
        url: `/management/organizations/${organizationId}/icon`,
        invalidatesTags: ["OrganizationIcon"]
      })
    }),
    getOrganizationIconById: builder.mutation<
      string,
      { organizationId: string }
    >({
      query: ({ organizationId }) => ({
        method: "GET",
        responseHandler: async (response) =>
          URL.createObjectURL(await response.blob()),
        url: `/management/organizations/${organizationId}/icon`,
        invalidatesTags: ["OrganizationIcon"]
      })
    })
  })
});

export const {
  useListOrganizationsQuery,
  useGetOrganizationMemberQuery,
  useAcceptInvitationMutation,
  useCreateOrganizationMutation,
  useDeleteInvitationMutation,
  useDeleteMemberFromOrganizationMutation,
  useDeleteOrganizationMutation,
  useGetOrganizationQuery,
  useInviteUserToOrganizationMutation,
  useListInvitationsToOrganizationQuery,
  useListPersonalInvitationsQuery,
  useRejectInvitationMutation,
  useRenameOrganizationMutation,
  useUpdateOrganizationMemberRoleMutation,
  useUploadOrganizationIconMutation,
  useGetOrganizationIconQuery,
  useGetOrganizationIconByIdMutation
} = organizationApi;
