import { faChevronDown } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Menu } from "primereact/menu";
import type { MenuItem } from "primereact/menuitem";
import type { IOrganizationMember } from "../../../app/services/types/organizationTypes";
import { useAppSelector } from "../../../app/hooks";
import { selectSelectedOrganization } from "../../createOrganization/organizationSlice";
import { useListRolesQuery } from "../../../app/services/roles";
import { useUpdateOrganizationMemberRoleMutation } from "../../../app/services/organization";
import { useRef } from "react";
import usePermission from "../../auth/usePermission";

const RoleSelector = ({
  rowData,
  refetchMembers
}: {
  rowData: IOrganizationMember;
  refetchMembers: () => void;
}) => {
  const organizationId = useAppSelector(
    selectSelectedOrganization
  ).organizationId;
  const { data: roles } = useListRolesQuery({ organizationId });
  const [updateOrganizationMemberRole] =
    useUpdateOrganizationMemberRoleMutation();
  const { organizationPermissions } = usePermission({});
  const menu = useRef<Menu>(null);

  const menuItems: MenuItem[] = [
    {
      label: "Admin",
      visible: organizationPermissions?.includes("manage"),
      command: (event) => {
        event.originalEvent.stopPropagation();

        void updateOrganizationMemberRole({
          organizationId,
          memberId: rowData.userId,
          role: "admin"
        }).then(() => {
          refetchMembers();
        });
      }
    },
    {
      label: "Member",
      visible: organizationPermissions?.includes("manage"),
      command: (event) => {
        event.originalEvent.stopPropagation();
        void updateOrganizationMemberRole({
          organizationId,
          memberId: rowData.userId,
          role: "member"
        }).then(() => {
          refetchMembers();
        });
      }
    }
  ];
  return (
    <>
      <button
        onClick={(event) => {
          menu.current?.toggle(event);
        }}
        className="bg-transparent cursor-pointer outline-none border-none flex align-items-center gap-2 pk-label-text-sm pk-text-primary p-0"
      >
        <FontAwesomeIcon icon={faChevronDown} />
      </button>
      <Menu
        className={"app-list-dashboard-context"}
        model={menuItems}
        popup
        ref={menu}
        pt={{
          menuitem: {
            className: "app-list-dashboard-context-p-menuitem"
          },
          icon: {
            className: "app-list-dashboard-context-p-menuitem-icon"
          },
          label: {
            className: "app-list-dashboard-context-p-menuitem-text"
          }
        }}
      />
    </>
  );
};

export default RoleSelector;
