import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../../features/auth/useAuth";
import type { ReactElement } from "react";
import usePermission from "../../features/auth/usePermission";
import type { Permission } from "../services/types/organizationTypes";

interface IProtectedRouteProps {
  roleRequired?: ("DEVELOPER" | "PARTNER_GUEST" | "USER" | "")[];
  permissionRequired?: {
    scope: "organization" | "workspace" | "project";
    permission: Permission;
  };
  children?: ReactElement<any, any>;
}

const ProtectedRoutes = (props: IProtectedRouteProps) => {
  const { children, roleRequired, permissionRequired } = props;
  const { organizationPermissions } = usePermission({});
  const { auth, role } = useAuth();

  //if the role required is there or not
  if (roleRequired && auth) {
    return role && roleRequired.includes(role) ? (
      children ? (
        children
      ) : (
        <Outlet />
      )
    ) : (
      <Navigate to="/denied" />
    );
  } else if (auth && permissionRequired && organizationPermissions) {
    switch (permissionRequired.scope) {
      case "organization":
        return organizationPermissions.includes(
          permissionRequired.permission
        ) ? (
          children ? (
            children
          ) : (
            <Outlet />
          )
        ) : (
          <Navigate to="/denied" />
        );
      case "workspace":
        return organizationPermissions.includes(permissionRequired.permission);
      case "project":
        return organizationPermissions.includes(permissionRequired.permission);
      default:
        return organizationPermissions.includes(permissionRequired.permission);
    }
  } else {
    return auth ? <Outlet /> : <Navigate to="/login" />;
  }
};

export default ProtectedRoutes;
