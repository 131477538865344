import { api } from "./api";
import type {
  IGetAllGrantsResponseForGroup,
  IGetAllGrantsResponseForUser,
  IGrantRoleOnWorkspaceArgs,
  IGrantRoleOnWorkspaceResponse,
  IRevokeRoleOnWorkspaceArgs,
  IWorkspace,
  IWorkspaces
} from "./types/workspaceTypes";

const workspaceApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createWorkspace: builder.mutation<
      IWorkspace,
      { name: string; description: string; organizationId: string }
    >({
      query: ({ name, description, organizationId }) => ({
        url: `/management/organizations/${organizationId}/workspaces`,
        method: "POST",
        body: {
          name,
          description
        }
      }),
      invalidatesTags: ["Workspaces"]
    }),
    updateWorkspace: builder.mutation<
      IWorkspace,
      {
        name: string;
        description: string;
        organizationId: string;
        workspaceId: string;
      }
    >({
      query: ({ name, description, organizationId, workspaceId }) => ({
        url: `/management/organizations/${organizationId}/workspaces/${workspaceId}`,
        method: "PUT",
        body: {
          name,
          description
        }
      }),
      invalidatesTags: ["Workspaces"]
    }),
    deleteWorkspace: builder.mutation<
      void,
      { organizationId: string; workspaceId: string }
    >({
      query: ({ organizationId, workspaceId }) => ({
        url: `/management/organizations/${organizationId}/workspaces/${workspaceId}`,
        method: "DELETE"
      }),
      invalidatesTags: ["Workspaces"]
    }),
    getWorkspace: builder.query<
      IWorkspace,
      { organizationId: string; workspaceId: string }
    >({
      query: ({ organizationId, workspaceId }) => ({
        url: `/management/organizations/${organizationId}/workspaces/${workspaceId}`,
        method: "GET"
      }),
      transformResponse: (response: IWorkspaces) => {
        return response.workspace;
      },
      providesTags: ["Workspaces"]
    }),
    listWorkspaces: builder.query<IWorkspaces[], { organizationId: string }>({
      query: ({ organizationId }) => ({
        url: `/management/organizations/${organizationId}/workspaces`,
        method: "GET"
      }),
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        return `${endpointName}-${queryArgs.organizationId}`;
      },
      providesTags: ["Workspaces"]
    }),
    grantRoleOnWorkspace: builder.mutation<
      IGrantRoleOnWorkspaceResponse,
      IGrantRoleOnWorkspaceArgs
    >({
      query: ({
        organizationId,
        workspaceId,
        entityId,
        entityType,
        roleId
      }) => ({
        url: `/management/organizations/${organizationId}/workspaces/${workspaceId}/grants`,
        method: "POST",
        body: {
          entityType,
          entityId,
          roleId
        }
      }),
      invalidatesTags: ["Grants"]
    }),
    updateGrantedRoleOnWorkspace: builder.mutation<
      IGrantRoleOnWorkspaceResponse,
      IGrantRoleOnWorkspaceArgs
    >({
      query: ({
        organizationId,
        workspaceId,
        entityId,
        entityType,
        roleId,
        grantId
      }) => ({
        url: `/management/organizations/${organizationId}/workspaces/${workspaceId}/grants/${grantId!}`,
        method: "PUT",
        body: {
          entityType,
          entityId,
          roleId
        }
      }),
      invalidatesTags: ["Grants"]
    }),
    revokeRoleOnWorkspace: builder.mutation<void, IRevokeRoleOnWorkspaceArgs>({
      query: ({ organizationId, workspaceId, grantId }) => ({
        url: `/management/organizations/${organizationId}/workspaces/${workspaceId}/grants/${grantId}`,
        method: "DELETE"
      }),
      invalidatesTags: ["Grants"]
    }),
    getAllGrants: builder.query<
      (IGetAllGrantsResponseForUser | IGetAllGrantsResponseForGroup)[],
      { organizationId: string; workspaceId: string }
    >({
      query: ({ organizationId, workspaceId }) => ({
        url: `/management/organizations/${organizationId}/workspaces/${workspaceId}/grants`,
        method: "GET"
      }),
      providesTags: ["Grants"]
    })
  })
});

export const {
  useCreateWorkspaceMutation,
  useListWorkspacesQuery,
  useGrantRoleOnWorkspaceMutation,
  useDeleteWorkspaceMutation,
  useGetAllGrantsQuery,
  useRevokeRoleOnWorkspaceMutation,
  useUpdateWorkspaceMutation,
  useGetWorkspaceQuery,
  useUpdateGrantedRoleOnWorkspaceMutation
} = workspaceApi;
