import {
  PeakaAvatar,
  PeakaMultiSelect
} from "@code2io/fe-studio-component-library";
import {
  PeakaButton,
  PeakaInputText,
  PeakaTextArea
} from "@code2io/fe-studio-component-library";
import PeakaDialog from "../../../app/utils/peakaDialog";
import {
  useAddMemberToGroupMutation,
  useCreateGroupMutation
} from "../../../app/services/group";
import { useGetOrganizationMemberQuery } from "../../../app/services/organization";
import { useAppSelector } from "../../../app/hooks";
import { selectSelectedOrganization } from "../../createOrganization/organizationSlice";
import { useRef, useState } from "react";
import toast from "../../../app/utils/toast/toast";
import type { IOrganizationMember } from "../../../app/services/types/organizationTypes";
import { useDebounce } from "primereact/hooks";
import { getUserLogo } from "../helpers";

const CreateGroupOrganizationModal = ({
  visible,
  onHide
}: {
  visible: boolean;
  onHide: () => void;
}) => {
  const organizationId = useAppSelector(
    selectSelectedOrganization
  ).organizationId;
  const [createGroup, { isLoading: groupCreating }] = useCreateGroupMutation();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [searchQuery, debouncedSearchQuery, setSearchQuery] = useDebounce(
    "",
    300
  );
  const { data: members, isLoading: membersLoading } =
    useGetOrganizationMemberQuery(
      {
        organizationId,
        pageNumber,
        pageSize: 40,
        searchQuery: debouncedSearchQuery
      },
      { skip: !organizationId }
    );
  const lastScrollTop = useRef(0);
  const loadMoreTimeoutId = useRef(0);
  const [addMemberToGroup] = useAddMemberToGroupMutation();
  const [addedMembers, setAddedMembers] = useState<IOrganizationMember[]>([]);
  const [name, setName] = useState<string>();
  const [description, setDescription] = useState<string>();

  return (
    <PeakaDialog
      visible={visible}
      onHide={() => {
        setAddedMembers([]);
        setName(undefined);
        setDescription(undefined);
        onHide();
      }}
      header="Create Group"
      content={
        <div className="flex flex-column gap-3">
          <PeakaInputText
            wrapperClassName="w-full"
            size="xl"
            label="Group Name"
            placeholder="Group Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />

          <PeakaTextArea
            wrapperClassName="w-full"
            size="lg"
            label="Group Description"
            placeholder="(Optional)"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          <PeakaMultiSelect
            value={addedMembers}
            filter
            size="xl"
            onFilter={(e) => setSearchQuery(e.filter)}
            label="Add Member"
            optionLabel="email"
            disabled={membersLoading}
            itemTemplate={(item) => (
              <div className="flex flex-row align-items-center gap-1">
                <PeakaAvatar
                  size="sm"
                  label={getUserLogo(
                    (item as IOrganizationMember).firstName,
                    (item as IOrganizationMember).lastName
                  )}
                />

                <div className="pk-label-text-sm pk-font-normal">
                  {item.email}
                </div>
              </div>
            )}
            onChange={(e) => {
              setAddedMembers(e.value);
            }}
            pt={{
              list: {
                onScrollCapture: (e) => {
                  const { scrollHeight, scrollTop, clientHeight } =
                    e.currentTarget;
                  if (
                    lastScrollTop.current !== scrollTop &&
                    Math.abs(scrollHeight - scrollTop - clientHeight) <= 1
                  ) {
                    clearTimeout(loadMoreTimeoutId.current);
                    loadMoreTimeoutId.current = window.setTimeout(() => {
                      setPageNumber(pageNumber + 1);
                      clearTimeout(loadMoreTimeoutId.current);
                    }, 10);
                  }
                }
              }
            }}
            options={members?.map((member) => ({
              ...member,
              label: `${member.firstName} ${member.lastName}`
            }))}
            placeholder="Select Member(s)"
            selectedItemsLabel="{0} members selected"
            maxSelectedLabels={3}
            wrapperClassName="w-full"
            className="w-full"
          />

          {/*<PeakaDropdown
            label="Add Member"
            placeholder="Select Member"
            size="lg"
            helpText="Only members within the organization can be added to this group."
            options={members}
            value={addedMember}
            optionValue="userId"
            filter
            pt={{
              list: {
                onScrollCapture: (e) => {
                  const { scrollHeight, scrollTop, clientHeight } =
                    e.currentTarget;
                  if (
                    lastScrollTop.current !== scrollTop &&
                    Math.abs(scrollHeight - scrollTop - clientHeight) <= 8
                  ) {
                    clearTimeout(loadMoreTimeoutId.current);
                    loadMoreTimeoutId.current = window.setTimeout(() => {
                      setPageNumber(pageNumber + 1);
                      clearTimeout(loadMoreTimeoutId.current);
                    }, 10);
                  }
                }
              }
            }}
            optionLabel="email"
            onChange={(e) => {
              setAddedMember(e.value);
            }}
            itemTemplate={(option) => {
              return (
                <div className="flex align-items-center gap-2">
                  <PeakaAvatar
                    size="sm"
                    label={getUserLogo(option.firstName, option.lastName)}
                  />
                  <span className="pk-font-medium pk-label-text-sm pk-text-primary">
                    {option.firstName} {option.lastName}
                  </span>
                </div>
              );
            }}
          />*/}
        </div>
      }
      footer={
        <div className="flex justify-content-end gap-2">
          <PeakaButton
            label="Cancel"
            severity="secondary"
            bordered
            onClick={() => {
              setAddedMembers([]);
              setName(undefined);
              setDescription(undefined);
              onHide();
            }}
          />
          <PeakaButton
            label="Create"
            loading={groupCreating}
            disabled={groupCreating || !name}
            onClick={async () => {
              if (!name || !organizationId) return;
              const created = await createGroup({
                organizationId,
                name,
                description
              });
              if ("data" in created) {
                if (addedMembers && addedMembers.length > 0) {
                  const responses = await Promise.all(
                    addedMembers.map((addedMember) => {
                      return addMemberToGroup({
                        organizationId,
                        groupId: created.data.id,
                        userId: addedMember.userId
                      });
                    })
                  );

                  if (responses.length === addedMembers.length) {
                    toast.current?.show({
                      severity: "success",
                      summary: "Success",
                      detail:
                        "Group is created and the member is added to group successfully.",
                      life: 5000
                    });
                    setAddedMembers([]);
                    setName(undefined);
                    setDescription(undefined);
                    onHide();
                    return;
                  }
                  toast.current?.show({
                    severity: "error",
                    summary: "Error while adding member",
                    detail:
                      "Group is created but some members could not be added to the group.",
                    life: 5000
                  });
                }
                toast.current?.show({
                  severity: "success",
                  summary: "Success",
                  detail: "Group is created successfully.",
                  life: 5000
                });
                setAddedMembers([]);
                setName(undefined);
                setDescription(undefined);
                onHide();
              }
            }}
          />
        </div>
      }
    />
  );
};

export default CreateGroupOrganizationModal;
