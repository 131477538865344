import type { ThunkAction, Action } from "@reduxjs/toolkit";
import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query/react";
import rtkQueryErrorHandlerMiddleware from "./utils/rtkQueryErrorHandlerMiddleware";
import designerReducer from "../features/designer/designerSlice";
import flowDesignerReducer from "../features/flowDesigner/flowDesignerSlice";
import c2ModalReducer from "./components/C2Modal/C2ModalSlice";
import dataModelReducer from "../features/dataModel/dataModelSlice";
import dataModelQueryReducer from "../features/dataModelQuery/dataModelQuerySlice";
import dataModelTableReducer from "../features/dataModelTable/dataModelTableSlice";
import authReducer from "../features/auth/authSlice";
import organizationReducer from "../features/createOrganization/organizationSlice";
import subscriptionReducer from "../features/subscription/subscriptionSlice";
import aiReducer from "../features/dataModelQuery/aiChatSlice";
import undoRedoReducer from "../features/undoRedo/undoRedoSlice";
import routerReducer from "../app/routing/routerSlice";
import localSessionReducer, {
  localSessionMiddleware
} from "../features/localSession/localSessionSlice";
import menuBarReducer from "./components/MenuBar/MenuBarSlice";
import appSettingsReducer from "../features/appSettings/AppSettingsSlice";
import { api, historyApi, oneDriveApi } from "./services/api";
import { collaborationApi } from "./services/collaboration/collaborationApi";
import { contactApi } from "./services/contactUs";

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    [collaborationApi.reducerPath]: collaborationApi.reducer,
    [contactApi.reducerPath]: contactApi.reducer,
    [oneDriveApi.reducerPath]: oneDriveApi.reducer,
    [historyApi.reducerPath]: historyApi.reducer,
    c2Modal: c2ModalReducer,
    dataModel: dataModelReducer,
    dataModelQuery: dataModelQueryReducer,
    dataModelTable: dataModelTableReducer,
    designer: designerReducer,
    flowDesigner: flowDesignerReducer,
    auth: authReducer,
    subscription: subscriptionReducer,
    ai: aiReducer,
    undoRedo: undoRedoReducer,
    routerReducer: routerReducer,
    localSession: localSessionReducer,
    appSettings: appSettingsReducer,
    menuBar: menuBarReducer,
    organization: organizationReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(api.middleware)
      .concat(collaborationApi.middleware)
      .concat(contactApi.middleware)
      .concat(oneDriveApi.middleware)
      .concat(historyApi.middleware)
      .concat(localSessionMiddleware)
      .concat(rtkQueryErrorHandlerMiddleware)
});

setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
