import { Outlet, useNavigate, useSearchParams } from "react-router-dom";
import C2Modal from "../components/C2Modal/C2Modal";
import { useCallback, useEffect, useState } from "react";
import { sendGAEvent } from "../utils/GAEventUtils";
import { AppConstants } from "../../environmentConstants";
import { useAppDispatch, useAppSelector } from "../hooks";
import {
  selectUser,
  setRefreshCode,
  setToken,
  setUser
} from "../../features/auth/authSlice";
import { useGetRefreshTokenQuery } from "../services/auth";
import jwt_decode from "jwt-decode";
import {
  identifyMXPanel,
  sendMixpanelEvent
} from "../utils/MixpanelEventUtils";
import { useListOrganizationsQuery } from "../services/organization";
import {
  selectSelectedOrganization,
  setSelectedOrganization
} from "../../features/createOrganization/organizationSlice";
import { useListGrantablePermissionsQuery } from "../services/roles";
import { getCookie, setCookie } from "../utils/setCookie";

const ParentRoute = () => {
  const user = useAppSelector(selectUser);
  const [ref, setRef] = useState<string | null>();
  const socialAuth = sessionStorage.getItem(
    AppConstants.SESSION_STORAGE_KEYS.SOCIAL_AUTH
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { data: refreshData, isLoading } = useGetRefreshTokenQuery();
  const selectedOrganization = useAppSelector(selectSelectedOrganization);
  const { data: grantablePermissions } = useListGrantablePermissionsQuery();
  const { data: organizationList } = useListOrganizationsQuery(null, {
    skip: !refreshData
  });
  const checkSocialSignup = useCallback(() => {
    if (!ref) {
      setRef(searchParams.get("ref"));
    }
    if (!user) return;
    if (
      socialAuth === AppConstants.GOOGLE_LOGIN &&
      ref === AppConstants.GOOGLE_LOGIN
    ) {
      sendGAEvent("studio_login", {
        method: "Google",
        email: user.email
      });
      sendMixpanelEvent("studio_login", {
        method: "Google",
        email: user.email
      });
      identifyMXPanel(user);
      sessionStorage.removeItem(AppConstants.SESSION_STORAGE_KEYS.SOCIAL_AUTH);
    } else if (
      socialAuth === AppConstants.GOOGLE_SIGNUP &&
      ref === AppConstants.GOOGLE_SIGNUP
    ) {
      sendGAEvent("studio_signup", {
        method: "Google",
        email: user.email
      });
      sendMixpanelEvent("studio_signup", {
        method: "Google",
        email: user.email
      });
      identifyMXPanel(user);
      sessionStorage.removeItem(AppConstants.SESSION_STORAGE_KEYS.SOCIAL_AUTH);
    } else if (socialAuth) {
      sessionStorage.removeItem(AppConstants.SESSION_STORAGE_KEYS.SOCIAL_AUTH);
    } else if (ref === AppConstants.PASSWORD_SIGNUP) {
      identifyMXPanel(user);
      setSearchParams();
    } else if (ref === AppConstants.PASSWORD_LOGIN) {
      identifyMXPanel(user);
      setSearchParams();
    }
    if (user.twoFactorAuthEnabled && !user.twoFactorAuthVerified) {
      navigate("/2fa-validation");
    }

    if (organizationList?.length === 0) {
      navigate("/create-organization");
    } else if (
      organizationList &&
      organizationList.length > 0 &&
      !selectedOrganization.organizationId
    ) {
      const organizationCookie = getCookie("selectedOrganization");

      if (
        organizationCookie &&
        JSON.parse(organizationCookie) &&
        organizationList.find(
          (organization) =>
            organization.organization.id ===
            JSON.parse(organizationCookie).organizationId
        )
      ) {
        const orgCookie = JSON.parse(organizationCookie);
        dispatch(
          setSelectedOrganization({
            selectedOrganization: {
              organizationName: orgCookie.organizationName,
              organizationId: orgCookie.organizationId
            }
          })
        );
      } else {
        const defaultOrganiztion =
          organizationList.filter(
            (organization) => organization.defaultOrganization
          )[0] ?? organizationList[0];
        dispatch(
          setSelectedOrganization({
            selectedOrganization: {
              organizationName: defaultOrganiztion.organization.name,
              organizationId: defaultOrganiztion.organization.id
            }
          })
        );
        setCookie(
          "selectedOrganization",
          JSON.stringify({
            organizationName: defaultOrganiztion.organization.name,
            organizationId: defaultOrganiztion.organization.id
          }),
          365
        );
      }
    }
  }, [navigate, ref, searchParams, socialAuth, user, organizationList?.length]);

  useEffect(() => {
    checkSocialSignup();
  }, [checkSocialSignup]);

  useEffect(() => {
    if (refreshData) {
      dispatch(setToken(refreshData.access_token));
      dispatch(setUser(jwt_decode(refreshData.access_token)));
      dispatch(setRefreshCode(refreshData.refresh_code));
    }
  }, [dispatch, refreshData]);

  return (
    !isLoading && (
      <>
        <Outlet />
        <C2Modal />
      </>
    )
  );
};

export default ParentRoute;
