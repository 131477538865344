import { PeakaBadge } from "@code2io/fe-studio-component-library";
import { faChevronDown } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OverlayPanel } from "primereact/overlaypanel";
import { useRef, type CSSProperties } from "react";
import type { IRole } from "../../app/services/types/organizationTypes";

const RoleSelector = ({
  value,
  onRoleChanged,
  className,
  style
}: {
  value: string;
  onRoleChanged: (role: IRole) => void;
  className?: string;
  style?: CSSProperties;
}) => {
  const op = useRef<OverlayPanel>(null);
  return (
    <span
      onClick={(e) => op.current?.toggle(e)}
      style={{ ...{ cursor: "pointer" }, ...style }}
      className={className}
    >
      <PeakaBadge
        text={value === "member" ? "Member" : value === "admin" ? "Admin" : ""}
        severity="highlight"
        size="sm"
        overlay={
          <div>
            <a>
              <FontAwesomeIcon icon={faChevronDown} size="xs" />
            </a>
            <OverlayPanel
              ref={op}
              unstyled
              className="app-list-dashboard-context p-menu p-component p-menu-overlay p-ripple-disabled p-connected-overlay-enter-done"
            >
              <div className="p-menu-list">
                <div
                  className="p-menuitem p-1 cursor-pointer pk-rounded-md"
                  onClick={() => onRoleChanged("member")}
                >
                  <a>Member</a>
                </div>
                <div
                  className="p-menuitem p-1 cursor-pointer pk-rounded-md"
                  onClick={() => onRoleChanged("admin")}
                >
                  <a>Admin</a>
                </div>
              </div>
            </OverlayPanel>
          </div>
        }
      />
    </span>
  );
};

export default RoleSelector;
