import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../app/store";

export interface ISelectedOrganizationState {
  selectedOrganization: {
    organizationId: string;
    organizationName: string;
  };
}

const initialState: ISelectedOrganizationState = {
  selectedOrganization: {
    organizationId: "",
    organizationName: ""
  }
};

export const organizationSlice = createSlice({
  name: "organization",
  initialState,
  reducers: {
    setSelectedOrganization: (
      state,
      action: PayloadAction<ISelectedOrganizationState>
    ) => {
      state.selectedOrganization = action.payload.selectedOrganization;
    }
  }
});

export const { setSelectedOrganization } = organizationSlice.actions;

export const selectSelectedOrganization = (state: RootState) =>
  state.organization.selectedOrganization;

export default organizationSlice.reducer;
