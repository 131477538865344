import { api } from "./api";
import type {
  ICreateGroupArgs,
  IGroup,
  IGroupInList,
  IGroupMember,
  IMemberAddedToGroupResponse
} from "./types/groupTypes";

export const groupApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createGroup: builder.mutation<IGroup, ICreateGroupArgs>({
      query: ({ organizationId, name, description }) => ({
        url: `/management/organizations/${organizationId}/groups`,
        method: "POST",
        body: {
          name,
          description
        }
      }),
      invalidatesTags: ["Groups"]
    }),
    listGroups: builder.query<IGroupInList[], { organizationId: string }>({
      query: ({ organizationId }) => ({
        url: `/management/organizations/${organizationId}/groups`,
        method: "GET"
      }),
      providesTags: ["Groups"]
    }),
    getGroup: builder.query<
      IGroupInList,
      { organizationId: string; groupId: string }
    >({
      query: ({ organizationId, groupId }) => ({
        url: `/management/organizations/${organizationId}/groups/${groupId}`,
        method: "GET"
      })
    }),
    getAllMembersByGroup: builder.mutation<
      IGroupMember[],
      { organizationId: string; groupId: string }
    >({
      query: ({ organizationId, groupId }) => ({
        url: `/management/organizations/${organizationId}/groups/${groupId}/members`,
        method: "GET"
      })
    }),
    getMembersByGroup: builder.query<
      IGroupMember[],
      { organizationId: string; groupId: string }
    >({
      query: ({ organizationId, groupId }) => ({
        url: `/management/organizations/${organizationId}/groups/${groupId}/members`,
        method: "GET"
      }),
      providesTags: ["Groups"]
    }),
    addMemberToGroup: builder.mutation<
      IMemberAddedToGroupResponse,
      { organizationId: string; groupId: string; userId: string }
    >({
      query: ({ organizationId, groupId, userId }) => ({
        url: `/management/organizations/${organizationId}/groups/${groupId}/members/${userId}`,
        method: "POST"
      }),
      invalidatesTags: ["Groups", "GroupMember"]
    }),
    removeMemberFromGroup: builder.mutation<
      void,
      { organizationId: string; groupId: string; userId: string }
    >({
      query: ({ organizationId, groupId, userId }) => ({
        url: `/management/organizations/${organizationId}/groups/${groupId}/members/${userId}`,
        method: "DELETE"
      }),
      invalidatesTags: ["Groups", "GroupMember"]
    }),
    deleteGroup: builder.mutation<
      void,
      { organizationId: string; groupId: string }
    >({
      query: ({ organizationId, groupId }) => ({
        url: `/management/organizations/${organizationId}/groups/${groupId}`,
        method: "DELETE"
      }),
      invalidatesTags: ["Groups"]
    }),
    updateGroup: builder.mutation<
      IGroup,
      {
        organizationId: string;
        groupId: string;
        name: string;
        description?: string;
      }
    >({
      query: ({ name, description, organizationId, groupId }) => ({
        url: `/management/organizations/${organizationId}/groups/${groupId}`,
        method: "PUT",
        body: {
          name,
          description
        }
      }),
      invalidatesTags: ["Groups"]
    })
  })
});

export const {
  useCreateGroupMutation,
  useListGroupsQuery,
  useGetGroupQuery,
  useGetAllMembersByGroupMutation,
  useAddMemberToGroupMutation,
  useDeleteGroupMutation,
  useUpdateGroupMutation,
  useRemoveMemberFromGroupMutation,
  useGetMembersByGroupQuery
} = groupApi;
