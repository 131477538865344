import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  selectSelectedOrganization,
  setSelectedOrganization
} from "../../createOrganization/organizationSlice";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useListWorkspacesQuery } from "../../../app/services/workspace";
import type { IOrganization } from "../../../app/services/types/organizationTypes";
import {
  PeakaButton,
  PeakaFileUpload,
  PeakaInputText,
  PeakaTextArea
} from "@code2io/fe-studio-component-library";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage } from "@fortawesome/pro-regular-svg-icons";
import toast from "../../../app/utils/toast/toast";
import PeakaDialog from "../../../app/utils/peakaDialog";
import {
  useDeleteOrganizationMutation,
  useGetOrganizationIconQuery,
  useGetOrganizationQuery,
  useListOrganizationsQuery,
  useRenameOrganizationMutation,
  useUploadOrganizationIconMutation
} from "../../../app/services/organization";
import { setCookie } from "../../../app/utils/setCookie";

const OrganizationSettings = () => {
  const organizationInfo = useAppSelector(selectSelectedOrganization);
  const { organizationId } = organizationInfo;
  const { data: organization } = useGetOrganizationQuery(
    { organizationId: organizationInfo.organizationId },
    { skip: !organizationInfo.organizationId }
  );
  const { data: organizations } = useListOrganizationsQuery();
  const [formData, setFormData] = useState<IOrganization | undefined>(
    organization?.organization
  );
  const [deleteConfirmVisible, setDeleteConfirmVisible] =
    useState<boolean>(false);
  const [confirmOrganizationName, setConfirmOrganizationName] =
    useState<string>();
  const navigate = useNavigate();
  const { data: listWorkspaces } = useListWorkspacesQuery({
    organizationId: organizationInfo.organizationId
  });
  const [updateOrganization, { isLoading: isOrganizationUpdating }] =
    useRenameOrganizationMutation();
  const [deleteOrganization, { isLoading: isDeleting }] =
    useDeleteOrganizationMutation();
  const dispatch = useAppDispatch();
  const { data: organizationIcon } = useGetOrganizationIconQuery(
    { organizationId },
    { skip: !organizationId }
  );
  const [uploadOrganizationIcon] = useUploadOrganizationIconMutation();
  const [imageLoaded, setImageLoaded] = useState<boolean>(true);
  const [tempImage, setTempImage] = useState<string>();

  useEffect(() => {
    if (!formData && organization) {
      setFormData(organization.organization);
    }
  }, [formData, setFormData, organization?.organization]);

  return (
    formData && (
      <div className="pk-padding-xl pk-bg-default pk-rounded-xl border border-solid pk-border-primary">
        <h3 className="pk-heading-text-xs pk-font-medium pk-text-label mb-2">
          Organization Image
        </h3>
        <div className="flex align-items-center gap-2">
          <span
            className="workspace-logo-container"
            style={{ width: "2.75rem", height: "2.75rem" }}
          >
            {!tempImage && (
              <img
                src={organizationIcon}
                onError={() => setImageLoaded(false)}
                onLoad={() => setImageLoaded(true)}
                className={!imageLoaded ? "hidden" : ""}
                style={{ width: "2.75rem", height: "2.75rem" }}
              />
            )}
            {!imageLoaded && !tempImage && (
              <i
                className="fa-kit fa-peaka-icon"
                style={{ fontSize: "2.75rem" }}
              />
            )}
            {tempImage && (
              <img
                src={tempImage}
                style={{ width: "2.75rem", height: "2.75rem" }}
              />
            )}
          </span>
          <PeakaFileUpload
            size="sm"
            bordered
            accept="image/*"
            onSelect={async (e) => {
              const bodyFormData = new FormData();
              bodyFormData.append("icon", e.files[0]);

              const data = await uploadOrganizationIcon({
                organizationId,
                icon: bodyFormData
              });
              if ("data" in data) {
                setFormData({ ...formData, iconTempFileId: data.data.fileId });
                setTempImage(URL.createObjectURL(e.files[0]));
              }
            }}
            chooseOptions={{
              label: "Change Image",
              icon: <FontAwesomeIcon icon={faImage} width={16} height={16} />
            }}
          />
        </div>

        <PeakaInputText
          label="Organization Name"
          wrapperClassName="w-full my-3"
          value={formData.name}
          onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          size="lg"
        />

        <PeakaTextArea
          label="Description"
          placeholder="Optional"
          wrapperClassName="w-full"
          value={formData.description}
          onChange={(e) =>
            setFormData({ ...formData, description: e.target.value })
          }
          size="lg"
        />

        <div className="w-full flex justify-content-between gap-2 mt-5">
          <PeakaButton
            label="Delete Organization"
            thin
            severity="destructive"
            size="lg"
            loading={isOrganizationUpdating}
            disabled={isOrganizationUpdating}
            onClick={() => setDeleteConfirmVisible(true)}
          />

          <PeakaButton
            className="w-full max-w-7rem"
            label="Save"
            size="lg"
            loading={isOrganizationUpdating}
            disabled={isOrganizationUpdating}
            onClick={async () => {
              const updateForm = {
                name: formData.name,
                description: formData.description,
                organizationId,
                iconTempFileId: formData.iconTempFileId
              };
              const updated = await updateOrganization(updateForm);
              if ("data" in updated) {
                toast.current?.show({
                  severity: "success",
                  summary: `Success`,
                  detail: "Organization updated successfully",
                  life: 5000,
                  closable: true
                });
              }
            }}
          />
        </div>
        <PeakaDialog
          visible={deleteConfirmVisible}
          size="sm"
          content={
            <div className="workspace-delete-confirm-modal flex flex-column gap-2">
              <div className="title">Confirm Deletion</div>
              <div className="description">
                To proceed, type the name of the organization you want to
                delete. This action is irreversible, and all associated data
                will be permanently removed.
              </div>
              <PeakaInputText
                size="lg"
                placeholder="Organization Name"
                wrapperClassName="w-full mt-1"
                onChange={(e) => setConfirmOrganizationName(e.target.value)}
              />
            </div>
          }
          header="Delete Organization"
          onHide={() => setDeleteConfirmVisible(false)}
          footer={
            <div className="flex flex-row justify-content-end gap-2">
              <PeakaButton
                label="Cancel"
                onClick={() => setDeleteConfirmVisible(false)}
                bordered
                severity="secondary"
              />
              <PeakaButton
                label="Delete"
                severity="destructive"
                disabled={
                  confirmOrganizationName !== organization?.organization.name ||
                  isDeleting
                }
                loading={isDeleting}
                onClick={async () => {
                  if (
                    organization?.organization &&
                    confirmOrganizationName === organization.organization.name
                  ) {
                    const deleted = await deleteOrganization({
                      organizationId: organizationInfo.organizationId
                    });

                    if ("data" in deleted) {
                      if (organizations && organizations.length > 0) {
                        const organization =
                          organizations.find((org) => org.defaultOrganization)
                            ?.organization ?? organizations[0]?.organization;
                        dispatch(
                          setSelectedOrganization({
                            selectedOrganization: {
                              organizationId: organization.id,
                              organizationName: organization.name
                            }
                          })
                        );
                        setCookie(
                          "selectedOrganization",
                          JSON.stringify({
                            organizationId: organization.id,
                            organizationName: organization.name
                          }),
                          365
                        );
                      }
                      toast.current?.show({
                        severity: "success",
                        summary: `Success`,
                        detail: "Organization is removed successfully",
                        life: 5000,
                        closable: true
                      });
                      navigate("/home");
                      setDeleteConfirmVisible(false);
                    }
                  }
                }}
              />
            </div>
          }
        />
      </div>
    )
  );
};

export default OrganizationSettings;
