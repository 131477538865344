import { useMemo } from "react";
import { useListOrganizationsQuery } from "../../app/services/organization";
import { useAppSelector } from "../../app/hooks";
import { selectSelectedOrganization } from "../createOrganization/organizationSlice";
import { useListWorkspacesQuery } from "../../app/services/workspace";
import { useGetProjectQuery } from "../../app/services/project";
import { useGetGroupQuery } from "../../app/services/group";

const usePermission = ({
  projectId,
  workspaceId,
  groupId,
  projectList = true
}: {
  projectId?: string;
  workspaceId?: string;
  groupId?: string;
  projectList?: boolean;
}) => {
  const { data: organizations, isLoading: isOrganizationsLoading } =
    useListOrganizationsQuery();
  const selectedOrganization = useAppSelector(selectSelectedOrganization);
  const organizationPermissions = useMemo(() => {
    return organizations?.find(
      (org) => org.organization.id === selectedOrganization.organizationId
    )?.permissions;
  }, [organizations, selectedOrganization.organizationId]);
  const { data: workspaces } = useListWorkspacesQuery(
    { organizationId: selectedOrganization.organizationId },
    { skip: !selectedOrganization.organizationId }
  );

  const { data: project } = useGetProjectQuery(
    {
      organizationId: selectedOrganization.organizationId,
      workspaceId: workspaceId!,
      projectId: projectId!
    },
    {
      skip:
        !selectedOrganization.organizationId ||
        !workspaceId ||
        !projectId ||
        projectList
    }
  );

  const { data: group } = useGetGroupQuery(
    { organizationId: selectedOrganization.organizationId, groupId: groupId! },
    { skip: !selectedOrganization.organizationId || !groupId }
  );

  const workspacesPermissions = () => {
    if (!workspaces) return null;
    const perms: { [key: string]: string[] } = {};
    for (const workspace of workspaces) {
      perms[workspace.workspace.id] = workspace.permissions;
    }
    return perms;
  };

  return {
    organizationPermissions,
    allWorkspacesPermissions: workspacesPermissions(),
    projectPermissions: project?.permissions ?? [],
    groupPermissions: group?.permissions ?? [],
    isOrganizationsLoading
  };
};

export default usePermission;
