import "primereact/resources/primereact.min.css";
import "@code2io/fe-theme/dist/themes/dark/theme.css";
import "@code2io/fe-theme/dist/themes/light/theme.css";
import "@code2io/fe-theme/dist/themes/lara-light-indigo/theme.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "@code2io/fe-c2-icons/dist/c2-icons.css";
import C2Router from "./app/routing/routes";
import { useEffect } from "react";
import zipy from "zipyai";
import { getDynamicConfigValue } from "./app/utils/dynamicConfig";
import { EnvironmentConstants } from "./environmentConstants";
import { useAppDispatch, useAppSelector } from "./app/hooks";
import { selectUser, setRefreshCode } from "./features/auth/authSlice";
import { useUnimpersonateUserMutation } from "./app/services/auth";
import { useEvaluationContextUpdate } from "./app/components/FrigadeOnboarding/index";
import { useLocation, useSearchParams } from "react-router-dom";
import { setVisible } from "./app/components/C2Modal/C2ModalSlice";
import { selectTheme, setTheme } from "./features/appSettings/AppSettingsSlice";
import mixpanel from "mixpanel-browser";
import { updateSourceMedia } from "./util/updateSourceMedia";
import { PeakaConfirmDialog } from "@code2io/fe-studio-component-library";
import { usePostHog } from "posthog-js/react";
import { loadSafeCss } from "./util/customCssLoader";
import { useGetEmbeddedCssMutation } from "./app/services/apiKey";

const App = () => {
  const dispatch = useAppDispatch();
  const zipyEnabled =
    getDynamicConfigValue(EnvironmentConstants.ZIPY_ENABLED) === "true";
  const zipyInit = zipyEnabled;
  const user = useAppSelector(selectUser);
  const [unimpersonate] = useUnimpersonateUserMutation();
  const location = useLocation();
  const posthog = usePostHog();
  const theme = useAppSelector(selectTheme);
  const [queryParam] = useSearchParams();
  const [getEmbeddedCss] = useGetEmbeddedCssMutation();

  const onErrorListener = (event: ErrorEvent) => {
    if (event.message.includes("Failed to fetch dynamically imported module")) {
      window.location.reload();
    }
  };

  const onMessageListener = (event: MessageEvent) => {
    if (event.data.theme) {
      if (event.data.themeOverride) {
        void getEmbeddedCss({ theme: event.data.theme }).then((data) => {
          if ("data" in data) {
            void loadSafeCss(
              undefined,
              (data.data as { cssFileContent: string }).cssFileContent
            );
          }
        });
      } else if (event.data.theme === "light" || event.data.theme === "dark") {
        if (location.pathname.includes("studio/")) {
          document.documentElement.setAttribute("theme", event.data.theme);
          updateSourceMedia(event.data.theme);
        } else {
          document.documentElement.setAttribute(
            "theme",
            event.data.theme === "dark" ? "light" : "dark"
          );
          updateSourceMedia(event.data.theme === "dark" ? "light" : "dark");
        }
        dispatch(setTheme({ theme: event.data.theme }));
      }
    }
  };

  useEffect(() => {
    window.addEventListener("message", onMessageListener);
    return () => {
      window.removeEventListener("message", onMessageListener);
    };
  }, []);

  useEffect(() => {
    const code = queryParam.get("refreshCode");
    if (
      code &&
      window.origin.includes(
        getDynamicConfigValue(EnvironmentConstants.PARTNER_DOMAIN)
      )
    ) {
      dispatch(setRefreshCode(code));
    }
  }, [dispatch, queryParam]);

  useEffect(() => {
    if (user?.partnerGuestUser) {
      const selectedTheme = queryParam.get("theme");
      const cssOverride = queryParam.get("themeOverride") === "true";
      if (!cssOverride && !selectedTheme) return;
      if (cssOverride && selectedTheme) {
        void getEmbeddedCss({ theme: selectedTheme }).then((data) => {
          if ("data" in data) {
            void loadSafeCss(
              undefined,
              (data.data as { cssFileContent: string }).cssFileContent
            );
          }
        });
      }
      if (
        !cssOverride &&
        selectedTheme &&
        (selectedTheme === "dark" || selectedTheme === "light")
      ) {
        if (location.pathname.includes("studio/")) {
          document.documentElement.setAttribute("theme", selectedTheme);
          updateSourceMedia(selectedTheme);
        } else {
          document.documentElement.setAttribute(
            "theme",
            selectedTheme === "dark" ? "light" : "dark"
          );
          updateSourceMedia(selectedTheme === "dark" ? "light" : "dark");
        }
        dispatch(setTheme({ theme: selectedTheme }));
      } else {
        if (location.pathname.includes("studio/")) {
          document.documentElement.setAttribute("theme", "dark");
          updateSourceMedia("dark");
        } else {
          document.documentElement.setAttribute("theme", "light");
          updateSourceMedia("light");
        }
        dispatch(setTheme({ theme: "dark" }));
      }
    }
  }, [
    dispatch,
    getEmbeddedCss,
    location.pathname,
    queryParam,
    theme,
    user?.partnerGuestUser
  ]);

  useEffect(() => {
    addEventListener("error", onErrorListener);
    if (!location.pathname.includes("home") && location.pathname !== "/") {
      if (location.pathname.includes("create-organization")) {
        document.documentElement.setAttribute("theme", "light");
      } else {
        updateSourceMedia(theme as "dark" | "light");
        document.documentElement.setAttribute("theme", theme);
      }
    }
    dispatch(setVisible(false));

    return () => {
      removeEventListener("error", onErrorListener);
    };
  }, [dispatch, location, theme]);

  useEvaluationContextUpdate("location", location);

  useEffect(() => {
    if (
      getDynamicConfigValue(EnvironmentConstants.MIXPANEL_ENABLED) == "true"
    ) {
      mixpanel.init("b55508c05cd4d9987bdef67fb0ab1623", {
        debug: false,
        track_pageview: "full-url",
        persistence: "localStorage"
      });
    }
    if (zipyInit) {
      zipy
        .init(getDynamicConfigValue(EnvironmentConstants.ZIPY_API_KEY), {
          releaseVer: getDynamicConfigValue(
            EnvironmentConstants.ZIPY_RELEASE_VERSION
          )
        })
        .then(() => {
          if (user) {
            if (user.email === "testautomation@peaka.com") {
              zipy.record.stop();
            }
            zipy.identify(user.email, {
              firstName: user.firstName,
              lastName: user.lastName ?? "",
              email: user.email,
              customerName: `${user.firstName} ${user.lastName ?? ""}`
            });
          }
          (window as any).zipy = zipy;
        })
        .catch((err) => console.error(err));
    }
  }, [user, zipyInit]);

  useEffect(() => {
    if (
      user &&
      getDynamicConfigValue(EnvironmentConstants.POSTHOG_ENABLED) == "true"
    ) {
      posthog.identify(user.username, {
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName ?? ""
      });

      const old_distinct_id = queryParam.get("distinct_id");
      if (old_distinct_id) {
        posthog.alias(old_distinct_id, user.email);
      }
    }
  }, [user, posthog, queryParam]);

  return (
    <>
      {user?.impersonatorId && (
        <>
          <div
            className="absolute pk-bg-tertiary p-2 pk-header-color"
            style={{ bottom: 0, right: 0, zIndex: 9999, opacity: 0.4 }}
          >
            <span>Impersonated ({user.email}) </span>
            <a
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={() => {
                unimpersonate()
                  .then(() => {
                    window.location.href = "/";
                  })
                  .catch((err) => {
                    console.error(err);
                  });
              }}
            >
              Back to my account
            </a>
          </div>
        </>
      )}
      <C2Router />
      <PeakaConfirmDialog />
    </>
  );
};

export default App;
