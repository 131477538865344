import {
  PeakaButton,
  PeakaInputText,
  PeakaStepper,
  PeakaTextArea
} from "@code2io/fe-studio-component-library";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState
} from "react";
import RoleSelector from "./roleSelector";
import { cloneDeep } from "lodash";
import success from "../../app/assets/svg/create-org-success.svg";
import { useNavigate } from "react-router-dom";
import {
  useCreateOrganizationMutation,
  useDeleteOrganizationMutation,
  useInviteUserToOrganizationMutation,
  useRenameOrganizationMutation
} from "../../app/services/organization";
import type { IRole } from "../../app/services/types/organizationTypes";
import { classNames } from "primereact/utils";
import { setSelectedOrganization } from "./organizationSlice";
import { useAppDispatch } from "../../app/hooks";
import { setCookie } from "../../app/utils/setCookie";

interface IInvitationForm {
  email: string;
  role: IRole;
}

interface CreateOrganizationFormData {
  organizationName: string;
  organizationDescription: string;
  invitations: IInvitationForm[];
  organizationId: string;
}

export interface IButtonStates {
  loading: boolean;
  disabled: boolean;
}

export interface IWizardButtonStates {
  step: string;
  nextButton: IButtonStates | null;
  prevButton: IButtonStates | null;
}

interface ICreateOrganizationPageArgs {
  className?: string;
  onFinished?: () => void;
  inModal?: boolean;
  onButtonStateChanged?: (buttonStates: IWizardButtonStates) => void;
}

const CreateOrganizationPage = forwardRef(
  (
    {
      className,
      onFinished,
      onButtonStateChanged,
      inModal
    }: ICreateOrganizationPageArgs,
    ref
  ) => {
    const [activeIndex, setActiveIndex] = useState<number>(0);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [createOrganization, { isLoading: isOrganizationCreating }] =
      useCreateOrganizationMutation();
    const [deleteOrganization] = useDeleteOrganizationMutation();
    const [inviteUserToOrganization, { isLoading: isInvitationSending }] =
      useInviteUserToOrganizationMutation();

    const [renameOrganization] = useRenameOrganizationMutation();

    const [formData, setFormData] = useState<CreateOrganizationFormData>({
      organizationName: "",
      organizationDescription: "",
      organizationId: "",
      invitations: [
        {
          email: "",
          role: "member"
        },
        {
          email: "",
          role: "member"
        },
        {
          email: "",
          role: "member"
        }
      ]
    });
    const model = [
      {
        label: "Organization Name"
      },
      {
        label: "Members & Permissions"
      },
      {
        label: "Finish Setup"
      }
    ];

    const stepChangeHandler = (index: number) => {
      //console.log("stepChanged", index);
    };

    const getButtonStates = useMemo(() => {
      if (activeIndex === 0) {
        return {
          next: {
            loading: isOrganizationCreating,
            disabled: !formData.organizationName || isOrganizationCreating
          },
          prev: null
        };
      } else if (activeIndex === 1) {
        return {
          next: {
            loading: isInvitationSending,
            disabled:
              !formData.invitations.some(
                (invitation) => invitation.email !== ""
              ) || isInvitationSending
          },
          prev: {
            loading: false,
            disabled: !formData.organizationName
          }
        };
      } else {
        return {
          next: {
            loading: false,
            disabled: false
          },
          prev: {
            loading: false,
            disabled: false
          }
        };
      }
    }, [
      activeIndex,
      formData.invitations,
      formData.organizationName,
      isInvitationSending,
      isOrganizationCreating
    ]);

    useEffect(() => {
      onButtonStateChanged?.({
        step:
          activeIndex === 0
            ? "orgCreation"
            : activeIndex === 1
            ? "invitations"
            : "final",
        nextButton: getButtonStates.next,
        prevButton: getButtonStates.prev
      });
    }, [
      activeIndex,
      formData.organizationName,
      getButtonStates.next,
      getButtonStates.prev,
      isOrganizationCreating
    ]);

    useImperativeHandle(ref, () => ({
      async createOrgAndProceed() {
        if (formData.organizationId) {
          const rename = await renameOrganization({
            organizationId: formData.organizationId,
            name: formData.organizationName,
            description: formData.organizationDescription,
            iconTempFileId: ""
          });
          if ("data" in rename) {
            setFormData({ ...formData, organizationName: rename.data.name });
            setActiveIndex(activeIndex + 1);
          }
        } else {
          const created = await createOrganization({
            name: formData.organizationName,
            description: formData.organizationDescription
          });
          if ("data" in created) {
            setFormData({ ...formData, organizationId: created.data.id });
            setActiveIndex(activeIndex + 1);
          }
        }
      },
      previousStep() {
        setActiveIndex(activeIndex - 1);
      },
      async inviteUsers() {
        for (const invitation of formData.invitations) {
          if (invitation.email) {
            const invited = await inviteUserToOrganization({
              organizationId: formData.organizationId,
              invitedUserEmail: invitation.email,
              role: invitation.role
            });
            if ("error" in invited) {
              return;
            }
          }
        }
        setActiveIndex(activeIndex + 1);
      },
      finalizeWizard() {
        if (formData.organizationId) {
          dispatch(
            setSelectedOrganization({
              selectedOrganization: {
                organizationId: formData.organizationId,
                organizationName: formData.organizationName
              }
            })
          );
          setCookie(
            "selectedOrganization",
            JSON.stringify({
              organizationId: formData.organizationId,
              organizationName: formData.organizationName
            }),
            365
          );
        }
        if (onFinished) {
          onFinished();
        } else {
          navigate("/");
        }
      }
    }));
    return (
      <div
        style={{ maxWidth: "608px", margin: "0 auto" }}
        className={classNames("mx-auto create-organization-wrapper", className)}
      >
        <PeakaStepper
          model={model}
          onStepChange={stepChangeHandler}
          activeIndex={activeIndex}
          containerClassName="pk-bg-default"
        >
          <div
            className={classNames("create-organization", inModal ? "p-0" : "")}
          >
            {!inModal && (
              <div className="title-description-wrapper">
                <h1 className="text-heading-md">Create Organization</h1>
                <p>
                  Build a space where your team can collaborate, share ideas,
                  and grow together. Start by creating an organization and
                  invite members to join your journey!
                </p>
              </div>
            )}
            <div
              className={classNames(
                inModal
                  ? "pk-padding-xl text-left"
                  : "organization-form-container"
              )}
            >
              <PeakaInputText
                label="Organization Name"
                wrapperClassName="w-full pb-4"
                onChange={(e) =>
                  setFormData({ ...formData, organizationName: e.target.value })
                }
                placeholder="ACME Org"
                helpText="After creating a organization, you can invite others to join."
              />
              {!inModal && (
                <PeakaTextArea
                  label="Description"
                  wrapperClassName="w-full pb-4"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      organizationDescription: e.target.value
                    })
                  }
                />
              )}
              {!inModal && (
                <PeakaButton
                  onClick={async () => {
                    const created = await createOrganization({
                      name: formData.organizationName,
                      description: formData.organizationDescription
                    });
                    if ("data" in created) {
                      setFormData({
                        ...formData,
                        organizationId: created.data.id
                      });
                      setActiveIndex(activeIndex + 1);
                    }
                  }}
                  label="Next"
                  disabled={
                    !formData.organizationName || isOrganizationCreating
                  }
                  loading={isOrganizationCreating}
                  className="w-full"
                />
              )}
            </div>
          </div>
          <div
            className={classNames(
              "create-organization",
              inModal ? "pb-0 px-0 pk-padding-top-xl" : ""
            )}
          >
            <div className="title-description-wrapper">
              <h1 className="text-heading-md">Members & Permissions</h1>
              <p>
                Invite your team, set roles, and collaborate seamlessly. Right
                access, better teamwork! 🚀
              </p>
            </div>
            <div
              className={classNames(
                inModal
                  ? "pk-padding-xl text-left"
                  : "organization-form-container"
              )}
            >
              {formData.invitations.map((invitation, index) => (
                <PeakaInputText
                  key={index}
                  wrapperClassName="w-full pb-4"
                  size="lg"
                  value={formData.invitations[index].email}
                  onChange={(e) => {
                    const newInvitations = cloneDeep(formData.invitations);
                    newInvitations[index].email = e.target.value;
                    setFormData({ ...formData, invitations: newInvitations });
                  }}
                  placeholder="Email"
                  withDropdown={
                    <RoleSelector
                      value={formData.invitations[index].role}
                      onRoleChanged={(role) => {
                        const newInvitations = cloneDeep(formData.invitations);
                        newInvitations[index].role = role;
                        setFormData({
                          ...formData,
                          invitations: newInvitations
                        });
                      }}
                    />
                  }
                />
              ))}
              <div className={inModal ? "pb-0" : "pb-4"}>
                <PeakaButton
                  size="md"
                  ghost
                  severity="secondary"
                  label="Skip for now"
                  onClick={() => setActiveIndex(activeIndex + 1)}
                  className="w-full underline"
                />
              </div>
              {!inModal && (
                <div className="flex justify-content-between w-full">
                  <PeakaButton
                    onClick={() => setActiveIndex(activeIndex - 1)}
                    label="Back"
                    disabled={!formData.organizationName}
                    severity="secondary"
                    bordered
                    className=""
                  />
                  <PeakaButton
                    onClick={async () => {
                      for (const invitation of formData.invitations) {
                        if (invitation.email) {
                          const invited = await inviteUserToOrganization({
                            organizationId: formData.organizationId,
                            invitedUserEmail: invitation.email,
                            role: invitation.role
                          });
                          if ("error" in invited) {
                            return;
                          }
                        }
                      }
                      setActiveIndex(activeIndex + 1);
                    }}
                    label="Next"
                    disabled={
                      !formData.invitations.some(
                        (invitation) => invitation.email !== ""
                      ) || isInvitationSending
                    }
                    loading={isInvitationSending}
                    className=""
                  />
                </div>
              )}
            </div>
          </div>
          <div
            className={classNames("create-organization", inModal ? "p-0" : "")}
          >
            <div
              className={classNames(
                inModal
                  ? "pk-padding-xl text-center"
                  : "organization-form-container text-center"
              )}
            >
              <div className="title-description-wrapper">
                <img src={success} className="pb-5" />
                <h1 className="text-heading-md">All Set & Ready! 🎉</h1>
                <p>
                  Everything is in place. Now, invite your team and kickstart
                  your projects!
                </p>
              </div>
              {!inModal && (
                <div className="flex justify-content-between w-full">
                  <PeakaButton
                    onClick={() => setActiveIndex(activeIndex - 1)}
                    label="Back"
                    disabled={!formData.organizationName}
                    severity="secondary"
                    bordered
                    className=""
                  />
                  <PeakaButton
                    onClick={() => {
                      if (formData.organizationId) {
                        dispatch(
                          setSelectedOrganization({
                            selectedOrganization: {
                              organizationId: formData.organizationId,
                              organizationName: formData.organizationName
                            }
                          })
                        );
                        setCookie(
                          "selectedOrganization",
                          JSON.stringify({
                            organizationId: formData.organizationId,
                            organizationName: formData.organizationName
                          }),
                          365
                        );
                      }
                      if (onFinished) {
                        onFinished();
                      } else {
                        navigate("/");
                      }
                    }}
                    label="Finish"
                    disabled={!formData.organizationName}
                    className=""
                  />
                </div>
              )}
            </div>
          </div>
        </PeakaStepper>
      </div>
    );
  }
);

CreateOrganizationPage.displayName = "CreateOrganizationPage";

export default CreateOrganizationPage;
